'use client';

import { bool, func, node, string } from 'prop-types';

import Icon from '../icon';

const ToggleDescription = ({ children, disabled = false, icon, onClick }) => {
  const childrenClassnames = ['flex', 'justify-start', 'items-start'];
  const iconClassnames = ['cursor-pointer'];

  if (disabled) {
    childrenClassnames.push('text-input-icon-disabled-color');
    iconClassnames.push('text-input-icon-disabled-color');
  } else {
    childrenClassnames.push('text-input-icon-color');
    iconClassnames.push('text-input-icon-color');
  }

  return (
    <div className={'flex flex-col'}>
      <div className="flex flex-row justify-between">
        <div className={childrenClassnames.join(' ')}>{children}</div>
        <div className={iconClassnames.join(' ')}>
          <Icon onClick={onClick} size="lg" type="solid" icon={icon} />
        </div>
      </div>
    </div>
  );
};

ToggleDescription.propTypes = {
  children: node.isRequired,
  disabled: bool,
  icon: string.isRequired,
  onClick: func.isRequired,
};

export default ToggleDescription;
