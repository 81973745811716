'use client';

import { Suspense, lazy, useMemo } from 'react';
import { string } from 'prop-types';

const Impl = lazy(() => import('./impl'));

const Icon = (props) => {
  const faClassName = useMemo(() => `fa-${props.size}`, [props.size]);

  const fallback = <div className={faClassName}>&nbsp;</div>;

  return (
    <Suspense fallback={fallback}>
      <Impl {...props} />
    </Suspense>
  );
};

Icon.propTypes = {
  size: string,
};

export default Icon;
