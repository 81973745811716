'use client';

import { bool, func } from 'prop-types';

const generateId = () => (Math.random() + 1).toString(36).substring(7);

const Checkbox = ({ onChange, checked }) => (
  <input
    id={generateId()}
    className={'accent-input-border-color'}
    checked={checked}
    type="checkbox"
    onChange={(e) => {
      onChange(e.currentTarget.checked);
    }}
  />
);

Checkbox.propTypes = {
  checked: bool.isRequired,
  onChange: func.isRequired,
};

export default Checkbox;
