'use client';

import { bool, func, node, string } from 'prop-types';

const Button = ({
  block = false,
  children,
  disabled = false,
  onClick,
  right = false,
  type = 'button',
}) => {
  const classNames = [
    'border',
    'border-button-border-color',
    'p-1.5',
    'rounded',
    'bg-button-background-color',
    'text-button-text-color',
    'focus:outline-0',
    'active:outline-0',
    'hover:outline-0',
    'disabled:!opacity-60',
    'disabled:!border',
  ];

  if (block) classNames.push('w-full');
  if (right) classNames.push('float-right');

  const className = classNames.join(' ');

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={className}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  block: bool,
  children: node.isRequired,
  disabled: bool,
  onClick: func,
  right: bool,
  type: string,
};

export default Button;
