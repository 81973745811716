'use client';

import { func, node, string } from 'prop-types';
import classnames from 'classnames';

import Icon from '../icon';

const className = classnames(
  'flex',
  'flex-row',
  'background-input-background-color',
  'border',
  'border-input-border-color',
  'rounded',
  'p-1.5'
);

const Toggle = ({ children, icon, onClick }) => (
  <div className={className}>
    <div className="flex items-center cursor-pointer text-input-icon-color px-2">
      <Icon onClick={onClick} size="lg" icon={icon} type="solid" />
    </div>
    <div>{children}</div>
  </div>
);

Toggle.propTypes = {
  children: node.isRequired,
  icon: string.isRequired,
  onClick: func.isRequired,
};

export default Toggle;
